<template>
  <div class="page">
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="type">申请{{ item.applyPlace }}开门二维码</div>
      <div class="time">申请时间 {{ item.insertTime }}</div>
      <div>
        <div
          v-if="item.auditStatus == 2"
          class="status"
          style="background: #f5f5f5; color: #aaafb9"
        >
          审核失败
        </div>
        <div
          v-if="item.auditStatus == 1"
          class="status"
          style="background: #007eff; color: #ffffff"
        >
          审核成功
        </div>
        <div
          v-if="item.auditStatus == 0"
          class="status"
          style="background: #ffb552; color: #ffffff"
        >
          审核中
        </div>
      </div>
    </div>
    <div class="nothing" v-if="list.length == 0">暂无申请历史</div>
  </div>
</template>

<script>
import { getApplyHistoryUrl } from "./api.js";
export default {
  name: "applicationHistory",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    // this.getList();
  },
  methods: {
    getList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getApplyHistoryUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #f1f2f5;
  padding-top: 30px;
  box-sizing: border-box;

  .nothing {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 60px;
  }

  .item {
    width: 696px;
    height: 168px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(144, 144, 144, 0.15);
    border-radius: 16px;
    margin: 0 auto 30px;
    position: relative;

    .status {
      width: 124px;
      height: 34px;
      border-radius: 17px;
      text-align: center;
      line-height: 34px;
      font-size: 24px;
      font-weight: 600;
      position: absolute;
      top: 30px;
      right: 42px;
    }

    .type {
      font-size: 28px;
      font-weight: 600;
      color: #1a1c34;
      position: absolute;
      top: 28px;
      left: 34px;
    }

    .time {
      position: absolute;
      top: 88px;
      left: 34px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
